import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { remCalc } from '../util/math'
import Layout from '../components/layout'
import ProjectGrid from '../components/projectGrid'

import SEO from '../components/seo'

const IndexPage = () => {
  const res = useStaticQuery(graphql`
    query {
      allPrismicProject (
        sort: {
          fields: data___date_created,
          order: [DESC]
        }
      ) {
        edges {
          node {
            uid
            data {
              title {
                text
              },
              featured_image {
                alt
                url
              }
              mini_project
              date_created (formatString: "YYYY")
            }
          }
        }
      }
    }
  `)


  const projects = res.allPrismicProject.edges.map((edge) => {
    const { data, uid } = edge.node
    return {
      ...data,
      uid
    }
  })
  const realProjects = projects.filter((p) => p.mini_project === 'false')
  const miniProjects = projects.filter((p) => p.mini_project === 'true')
  return (
    <Layout>
      <SEO title="Projects" description="Explore product and digital design projects by the swedish designer Kristoffer Eriksson." />
      <div className="section-intro">
        <h1>
          I am Kristoffer Eriksson.
          <br />
          A swedish designer / engineer.
          <br />
          This is my work.
        </h1>
      </div>
      <ProjectGrid projects={realProjects} />
      <div className="section-intro">
        <h1>
          Mini projects.
          <br />
          Hobby / Art / School.
        </h1>
      </div>
      <ProjectGrid projects={miniProjects} />
      <style jsx>
        {`
      .section-intro  {
        padding: 2rem 0;
        max-width: ${remCalc(800)};
        margin: 4rem auto;
        h1 {
          margin: 0;
        }
      }
    `}
      </style>
    </Layout>
  )
}

export default IndexPage

import { Link } from 'gatsby'
import React from 'react'
import PropTypes from 'prop-types'
import sizes from '../config/sizes'


const ProjectGrid = ({ projects }) => (
  <div className="project-grid">
    {projects.map((p) => (
      <div className="project-container" key={p.uid}>
        <Link to={`/project/${p.uid}`}>
          <div className="project" style={{ backgroundImage: `url(${p.featured_image.url})` }}>
            <div className="overlay">
              <div>
                <h2>{p.title.text}</h2>
                <p>{p.date_created}</p>
              </div>
            </div>
          </div>
        </Link>
      </div>
    ))}
    <style jsx>
      {`
          .project-grid {
            display: flex;
            flex-flow: row wrap;
            position: relative;
          }

          .project-container {
            width: 100%;
            padding-bottom: 1rem;
            @media only screen and (min-width: ${sizes.md}px) {
              width: 50%;
              padding-bottom: 0.5rem;
              padding-right: 0.25rem;
              &:nth-child(2n) {
                padding-right: 0;
                padding-left: 0.25rem;
              }
            }
            :global(a) {
              display: block;
              width: 100%;
              height: 0;
              padding-top: 68.1%;
              background: #fff;
              position: relative;
            }
          }

          .project {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-size: cover;
            &:hover {
              .overlay {
                opacity: 1;
              }
            }
          }

          .overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(0,0,0, .3);
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: #fff;
            display: flex;
            opacity: 0;
            transition: all 0.3s;

            h2 {
              text-align: center;
              margin: 0;
              margin-bottom: 0.3em;
              color: #fff;
            }

            p {
              text-align: center;
              margin: 0;
            }
          }
      `}
    </style>
  </div>
)

ProjectGrid.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.shape({
      text: PropTypes.string.isRequired
    }),
    date: PropTypes.string.isRequired,
    featured_image: PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired
  })).isRequired
}

export default ProjectGrid
